import React, { useCallback, useEffect, useRef, useState } from 'react';
import './Studio.css';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SplitType from 'split-type';
import icon1 from './elm 1@4x-8.png';
import icon2 from './elm 2@4x-8.png';
import icon3 from './elm 3@4x-8.png';

gsap.registerPlugin(ScrollTrigger);

const Studio = () => {
  const [banner, setBanner] = useState({});
  const [info, setInfo] = useState({});
  const [team, setTeam] = useState([]);
  const [teamFounder, setTeamFounder] = useState({});
  const [brand, setBrand] = useState([]);
  const [image, setImage] = useState({});
  const [client, setClient] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState(0);

  const main = useRef([]);

  const setRefs = useCallback((el) => {
    if (el && !main.current.includes(el)) {
      main.current.push(el);
    }
  }, []);

  const handleClientClick = (index) => {
    setSelectedClient((prevActiveIndex) =>
      prevActiveIndex === index ? index : index
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [bannerRes, infoRes, teamRes, imageRes, brand, client] =
          await Promise.all([
            fetch('https://api.chuckmotion.com/studio/get-banner').then((res) =>
              res.json()
            ),
            fetch('https://api.chuckmotion.com/studio/get-info').then((res) =>
              res.json()
            ),
            fetch('https://api.chuckmotion.com/studio/get-team').then((res) =>
              res.json()
            ),
            fetch('https://api.chuckmotion.com/studio/get-image').then((res) =>
              res.json()
            ),
            fetch('https://api.chuckmotion.com/studio/get-brand').then((res) =>
              res.json()
            ),
            fetch('https://api.chuckmotion.com/studio/get-review').then((res) =>
              res.json()
            ),
          ]);

        setBanner(bannerRes);
        setInfo(infoRes);
        setTeam(teamRes);
        setTeamFounder(teamRes[1]);
        setImage(imageRes);
        setBrand(brand);
        setClient(client);

        document.title = 'Studio - Chuck Motion'; // Set document title after fetching data
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setLoading(true);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (loading) {
      const splitTypes = gsap.utils.toArray('.studio-about-content p');
      const textDesc = document.querySelectorAll('.studio-content-text');
      const boxes = gsap.utils.toArray('.studio-member-item');
      const textTop = gsap.utils.toArray('.studio-about-top h2');
      const textBottom = gsap.utils.toArray('.studio-about-bottom h2');
      const imgTop = gsap.utils.toArray('.studio-about-top img');
      const imgBot1 = gsap.utils.toArray('.studio-about-bottom .icon-2');
      const imgBot2 = gsap.utils.toArray('.studio-about-bottom .icon-3');
      textDesc.forEach((char) => {
        const bg = char.dataset.bgColor;
        const fg = char.dataset.fgColor;
        const text = new SplitType(char, { types: 'chars' });
        gsap.fromTo(
          text.chars,
          { color: bg },
          {
            color: fg,
            duration: 1.5,
            stagger: 0.1,
            scrollTrigger: {
              trigger: char,
              start: 'top 60%',
              end: 'top 0%',
              scrub: true,
            },
          }
        );
      });
      splitTypes.forEach((box, index) => {
        gsap.fromTo(
          box,
          {
            opacity: 0,
            y: 80,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            scrollTrigger: {
              trigger: box,
              start: 'top 70%',
              toggleActions: 'play none none none',
            },
          }
        );
      });
      boxes.forEach((box, index) => {
        const delay = index === 0 ? 0 : index * 0.2;
        gsap.fromTo(
          box,
          {
            opacity: 0,
            y: 80,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            delay: delay,
            scrollTrigger: {
              trigger: box,
              start: 'top 70%',
              toggleActions: 'play none none none',
            },
          }
        );
      });
      textTop.forEach((box, index) => {
        gsap.fromTo(
          box,
          {
            opacity: 0,
            y: 80,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            scrollTrigger: {
              trigger: box,
              start: 'top 70%',
              toggleActions: 'play none none none',
            },
          }
        );
      });
      textBottom.forEach((box, index) => {
        gsap.fromTo(
          box,
          {
            opacity: 0,
            y: 80,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            delay: 0.3,
            scrollTrigger: {
              trigger: box,
              start: 'top 70%',
              toggleActions: 'play none none none',
            },
          }
        );
      });
      imgTop.forEach((box) => {
        gsap.fromTo(
          box,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.5,
            delay: 0.3,
            scrollTrigger: {
              trigger: box,
              start: 'top 70%',
              toggleActions: 'play none none none',
            },
          }
        );
      });
      imgBot2.forEach((box) => {
        gsap.fromTo(
          box,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.5,
            delay: 0.5,
            scrollTrigger: {
              trigger: box,
              start: 'top 70%',
              toggleActions: 'play none none none',
            },
          }
        );
      });
      imgBot1.forEach((box) => {
        gsap.fromTo(
          box,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.5,
            delay: 0.5,
            scrollTrigger: {
              trigger: box,
              start: 'top 70%',
              toggleActions: 'play none none none',
            },
          }
        );
      });
    }
  }, [loading]);

  useEffect(() => {
    return () => {
      main.current = [];
    };
  }, []);

  return (
    loading && (
      <div
        className="studio"
        ref={setRefs}>
        <section className="studio-banner">
          <img
            src={banner.banner}
            alt=""
          />
        </section>
        <section className="studio-about">
          <div className="container">
            <div className="studio-about-content column col-8">
              <div className="studio-about-top">
                <h2>Where creativity</h2>
                <img
                  alt="icon-1"
                  src={icon1}
                />
                <h2>Sparks</h2>
              </div>
              <div className="studio-about-bottom">
                <img
                  className="icon-2"
                  alt="icon-2"
                  src={icon2}
                />
                <h2>For Motion Graphic!</h2>
                <img
                  className="icon-3"
                  alt="icon-3"
                  src={icon3}
                />
              </div>
              <p
                className="studio-about-text"
                dangerouslySetInnerHTML={{ __html: info.content }}
              />
            </div>
          </div>
        </section>
        <section className="studio-member">
          <div className="container">
            <div className="studio-member-list column col-8">
              <div className={`studio-member-item founder`}>
                <div className="studio-member-image">
                  <img
                    className="studio-member-avatar"
                    src={teamFounder.avatar}
                    alt={teamFounder.name}
                  />
                  <img
                    className="studio-member-bg"
                    src={teamFounder.hover_avatar}
                    alt={teamFounder.name}
                  />
                </div>
                <div className="studio-member-info">
                  <h3 dangerouslySetInnerHTML={{ __html: teamFounder.name }} />
                  <p
                    dangerouslySetInnerHTML={{ __html: teamFounder.position }}
                  />
                </div>
              </div>
              {team.map((member, index) => (
                <div
                  className={`studio-member-item item-${index + 1} `}
                  key={index}>
                  <div className="studio-member-image">
                    <img
                      className="studio-member-avatar"
                      src={member.avatar}
                      alt={member.name}
                    />
                    <img
                      className="studio-member-bg"
                      src={member.hover_avatar}
                      alt={member.name}
                    />
                  </div>
                  <div className="studio-member-info">
                    <h3 dangerouslySetInnerHTML={{ __html: member.name }} />
                    <p dangerouslySetInnerHTML={{ __html: member.position }} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="studio-brand">
          <div className="container">
            <div className="studio-brand-heading column col-3">
              <p className="column col-8">Client & Parnert</p>
            </div>
            <div className="studio-brand-list column col-7">
              {brand.map((brand, index) => (
                <div
                  className="studio-brand-item column"
                  key={index}>
                  <img
                    src={brand.logo}
                    alt={brand.name}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="studio-review">
          <div className="container">
            <div className="studio-review-heading column col-3">
              <p className="column col-8">Client & Parnert Review</p>
            </div>
            <div className="studio-review-name column col-2">
              {client.map((client, index) => (
                <div
                  key={index}
                  className={`studio-review-item ${
                    selectedClient === index ? 'selected' : ''
                  }`}
                  onClick={() => handleClientClick(index)}
                  dangerouslySetInnerHTML={{ __html: client.name }}
                />
              ))}
            </div>
            <div className="studio-review-info column col-5">
              {client.map(
                (client, index) =>
                  selectedClient === index && (
                    <p
                      className="studio-review-text"
                      dangerouslySetInnerHTML={{ __html: client.content }}
                    />
                  )
              )}
            </div>
          </div>
        </section>
        <section className="studio-image">
          <div className="container">
            <div className="studio-image-col column col-2">
              <div className="studio-image-item item-1 column">
                <img
                  src={image.image_1}
                  alt="image_1"
                />
                <div className="studio-image-content"></div>
              </div>
              <div className="studio-image-item item-2 column">
                <img
                  src={image.image_2}
                  alt="image_2"
                />
                <div className="studio-image-content"></div>
              </div>
            </div>
            <div className="studio-image-col column col-2">
              <div className="studio-image-item item-3 column">
                <img
                  src={image.image_3}
                  alt="image_3"
                />
                <div className="studio-image-content"></div>
              </div>
            </div>
            <div className="studio-image-col column col-4">
              <div className="studio-image-item item-4 column">
                <img
                  src={image.image_4}
                  alt="image_4"
                />
                <div className="studio-image-content"></div>
              </div>
            </div>
            <div className="studio-image-col column col-4">
              <div className="studio-image-item item-5 column">
                <img
                  src={image.image_5}
                  alt="image_5"
                />
                <div className="studio-image-content"></div>
              </div>
              <div className="studio-image-item item-6 column">
                <img
                  src={image.image_6}
                  alt="image_6"
                />
                <div className="studio-image-content"></div>
              </div>
              <div className="studio-image-item item-7 column">
                <img
                  src={image.image_7}
                  alt="image_7"
                />
                <div className="studio-image-content"></div>
              </div>
            </div>
            <div className="studio-image-col column col-12">
              <div className="studio-image-item item-8 column">
                <img
                  src={image.image_8}
                  alt="image_8"
                />
                <div className="studio-image-content"></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  );
};

export default Studio;
