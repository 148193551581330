import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = ({ headerMeta, infomation, social }) => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-left column col-6">
          <div className="footer-logo">
            <img
              src={headerMeta.logo}
              alt="logo"
            />
          </div>
          <div className="footer-back-link column">
            <p>Site by: leodang.com</p>
            <p>Copyright 2024 by Chuck Motion.</p>
          </div>
        </div>
        <div className="footer-right column col-6">
          <div className="footer-top column">
            <h3 className="footer-top-content">User</h3>
            <h3 className="footer-top-content">Unique</h3>
            <h3 className="footer-top-content">Universal</h3>
          </div>
          <div className="footer-info column">
            <div className="footer-info-item column">
              <p
                className="footer-info-text"
                dangerouslySetInnerHTML={{ __html: infomation.info_content_2 }}
              />
              <p
                className="footer-info-text"
                dangerouslySetInnerHTML={{ __html: infomation.info_content_1 }}
              />
            </div>
            <div className="footer-info-item column">
              <p
                className="footer-info-text"
                dangerouslySetInnerHTML={{ __html: infomation.info_content_3 }}
              />
            </div>
          </div>
          <ul className="footer-social column">
            <li>
              <Link
                to={social.link_1}
                target="_blank">
                {social.social_1}
              </Link>
            </li>
            <li>
              <Link
                to={social.link_2}
                target="_blank">
                {social.social_2}
              </Link>
            </li>
            <li>
              <Link
                to={social.link_3}
                target="_blank">
                {social.social_3}
              </Link>
            </li>
            <li>
              <Link
                to={social.link_4}
                target="_blank">
                {social.social_4}
              </Link>
            </li>
            <li>
              <Link
                to={social.link_5}
                target="_blank">
                {social.social_5}
              </Link>
            </li>
            <li>
              <Link
                to={social.link_6}
                target="_blank">
                {social.social_6}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
