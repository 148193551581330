import gsap from 'gsap';

// Declare a general timeline to use in all the animation functions.

const tl = gsap.timeline();

export const preLoaderAnim = () => {
  tl.to('.loading-cricle', {
    duration: 1,
    y: 0,
    opacity: 1,
    ease: 'Power3.easeOut',
  })
    .to('.loading', {
      duration: 1,
      delay: 1.5,
      css: { overflowY: 'hidden', height: '0vh' },
      ease: 'Power3.easeOut',
    })
    .to('.loading', {
      duration: 0,
      css: { display: 'none' },
    });
};
