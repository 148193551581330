import React, { useEffect, useState } from 'react';
import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './Components/Home/Home';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import WorkDetail from './Components/WorkDetail/WorkDetail';
import Expertise from './Components/Expertise/Expertise';
import BlogDetail from './Components/BlogDetail/BlogDetail';
import Blog from './Components/Blog/Blog';
import Process from './Components/Process/Process';
import Enquires from './Components/Enquires/Enquires';
import Studio from './Components/Studio/Studio';
import Loading from './Components/Loading/Loading';
import Loaded from './Components/Loading/Loaded';

const App = ({ headerMeta }) => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [menu, setMenu] = useState({});
  const [infomation, setInfomation] = useState({});
  const [social, setSocial] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [menuRes, infoRes, socialRes] = await Promise.all([
          fetch('https://api.chuckmotion.com/header/get-menu'),
          fetch('https://api.chuckmotion.com/header/get-info'),
          fetch('https://api.chuckmotion.com/header/get-social'),
        ]);

        const [menuText, infoText, socialText] = await Promise.all([
          menuRes.text(),
          infoRes.text(),
          socialRes.text(),
        ]);

        const menuData = menuText ? JSON.parse(menuText) : {};
        const infoData = infoText ? JSON.parse(infoText) : {};
        const socialData = socialText ? JSON.parse(socialText) : {};

        setMenu(menuData);
        setInfomation(infoData);
        setSocial(socialData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [location]);

  return (
    <div className="wrapper">
      {loading ? (
        <Loading />
      ) : (
        <>
          <Loaded />
          <Header
            menu={menu}
            headerMeta={headerMeta}
            infomation={infomation}
            social={social}
          />
          <main className="main">
            <Routes>
              <Route
                path="/"
                element={<Home />}
              />
              <Route
                path={`/${menu.menu_1.toLowerCase()}`}
                element={<Home />}
              />
              <Route
                path={`/${menu.menu_1.toLowerCase()}/:slug`}
                element={<WorkDetail />}
              />
              <Route
                path={`/${menu.menu_2.toLowerCase()}`}
                element={<Expertise />}
              />
              <Route
                path={`/${menu.menu_6.toLowerCase()}/:slug`}
                element={<BlogDetail />}
              />
              <Route
                path={`/${menu.menu_6.toLowerCase()}`}
                element={<Blog />}
              />
              <Route
                path={`/${menu.menu_3.toLowerCase()}`}
                element={<Process />}
              />
              <Route
                path={`/${menu.menu_5.toLowerCase()}`}
                element={<Enquires />}
              />
              <Route
                path={`/${menu.menu_4.toLowerCase()}`}
                element={<Studio />}
              />
            </Routes>
          </main>
          <Footer
            headerMeta={headerMeta}
            infomation={infomation}
            social={social}
          />
        </>
      )}
    </div>
  );
};

export default App;
