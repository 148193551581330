import React, { useEffect, useRef, useState } from 'react';
import './WorkDetail.css';
import MoreWork from '../MoreWork/MoreWork';
import { useLocation } from 'react-router-dom';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const WorkDetail = () => {
  const [workDetail, setWorkDetail] = useState({});
  const [category, setCategory] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const location = useLocation();
  const { project_id } = location.state || {};

  const main = useRef();

  function stripHtml(html) {
    var tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [projectResponse, tagResponse] = await Promise.all([
          fetch(
            `https://api.chuckmotion.com/projects/get-project/${project_id}`
          ),
          fetch(
            `https://api.chuckmotion.com/projects/get-project-tag/${project_id}`
          ),
        ]);

        const projectData = await projectResponse.json();
        const tagText = await tagResponse.text();
        const tagData = tagText ? JSON.parse(tagText) : {};

        const textTitle = stripHtml(projectData.heading);
        document.title = `${textTitle} - Chuck Motion`;

        setWorkDetail(projectData);
        setCategory(tagData);
        setLoaded(true);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, [project_id]);

  useEffect(() => {
    if (loaded) {
      const boxes = gsap.utils.toArray('.work-detail-content p');
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          {
            opacity: 0,
            y: 80,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            scrollTrigger: {
              trigger: box,
              start: 'top 70%',
              toggleActions: 'play none none none',
            },
          }
        );
      });
    }
  }, [loaded]);

  // function formatDate(isoString) {
  //   const date = new Date(isoString);

  //   const options = {
  //     month: 'long',
  //     day: 'numeric',
  //     timeZone: 'Asia/Ho_Chi_Minh',
  //   };

  //   return date.toLocaleDateString('en-US', options);
  // }

  const combinedHTML = `${stripHtml(workDetail.heading)}`;

  return (
    loaded && (
      <div>
        <section className="work-detail">
          <div className="container">
            <div className="work-detail-header column col-8">
              <div className="work-detail-tags-list">
                {category.map((item, index) => (
                  <React.Fragment key={index}>
                    <p dangerouslySetInnerHTML={{ __html: item.name }} />
                    {index < category.length - 1 && ', '}
                  </React.Fragment>
                ))}
              </div>
              <h1 className="work-detail-heading">{combinedHTML}</h1>
            </div>
            <div
              className="work-detail-content column col-8"
              ref={main}
              dangerouslySetInnerHTML={{ __html: workDetail.content }}
            />
          </div>
        </section>
        <MoreWork
          project_id={project_id}
          size={workDetail.size}
        />
      </div>
    )
  );
};

export default WorkDetail;
