import React, { useRef } from 'react';
import './HomeContent.css';
import icon1 from './icon-1.png';
import icon2 from './icon-2.png';
import icon3 from './icon-3.png';
import icon4 from './icon-4.png';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(useGSAP, ScrollTrigger);

const HomeContent = ({ information }) => {
  const main = useRef();

  useGSAP(
    () => {
      const boxes = gsap.utils.toArray('.home-content-text p');
      const textTop = gsap.utils.toArray('.home-content-top h2');
      const textMiddle = gsap.utils.toArray('.home-content-middle h2');
      const textBottom = gsap.utils.toArray('.home-content-bottom h2');
      const imgTop = gsap.utils.toArray('.home-content-top img');
      const imgBot1 = gsap.utils.toArray('.home-content-bottom .icon-1');
      const imgBot2 = gsap.utils.toArray('.home-content-bottom .icon-2');
      const imgBot3 = gsap.utils.toArray('.home-content-bottom .icon-3');
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          {
            opacity: 0,
            y: 80,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            scrollTrigger: {
              trigger: box,
              start: 'top 80%',
              toggleActions: 'play none none none',
            },
          }
        );
      });
      textTop.forEach((box, index) => {
        gsap.fromTo(
          box,
          {
            opacity: 0,
            y: 80,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            scrollTrigger: {
              trigger: box,
              start: 'top 70%',
              toggleActions: 'play none none none',
            },
          }
        );
      });
      textBottom.forEach((box, index) => {
        gsap.fromTo(
          box,
          {
            opacity: 0,
            y: 80,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            delay: 0.3,
            scrollTrigger: {
              trigger: box,
              start: 'top 70%',
              toggleActions: 'play none none none',
            },
          }
        );
      });
      textMiddle.forEach((box) => {
        gsap.fromTo(
          box,
          {
            opacity: 0,
            y: 40,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            delay: 0.3,
            scrollTrigger: {
              trigger: box,
              start: 'top 70%',
              toggleActions: 'play none none none',
            },
          }
        );
      });
      imgTop.forEach((box) => {
        gsap.fromTo(
          box,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.5,
            delay: 0,
            scrollTrigger: {
              trigger: box,
              start: 'top 70%',
              toggleActions: 'play none none none',
            },
          }
        );
      });
      imgBot2.forEach((box) => {
        gsap.fromTo(
          box,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.5,
            delay: 0,
            scrollTrigger: {
              trigger: box,
              start: 'top 70%',
              toggleActions: 'play none none none',
            },
          }
        );
      });
      imgBot1.forEach((box) => {
        gsap.fromTo(
          box,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.5,
            delay: 0,
            scrollTrigger: {
              trigger: box,
              start: 'top 70%',
              toggleActions: 'play none none none',
            },
          }
        );
      });
      imgBot3.forEach((box) => {
        gsap.fromTo(
          box,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.5,
            delay: 0,
            scrollTrigger: {
              trigger: box,
              start: 'top 70%',
              toggleActions: 'play none none none',
            },
          }
        );
      });
    },
    { scope: main }
  );

  return (
    <section className="home-content">
      <div className="container">
        <div
          className="home-content-wrapper column col-8"
          ref={main}>
          <div className="home-content-top">
            <h2>More than</h2>
            <img
              alt="icon-1"
              src={icon1}
            />
            <h2>Just images</h2>
          </div>
          <div className="home-content-middle">
            <h2>It's the heartbeatfor</h2>
          </div>
          <div className="home-content-bottom">
            <img
              className="icon-1"
              src={icon2}
              alt="icon-2"
            />
            <h2>Your</h2>
            <img
              className="icon-2"
              src={icon3}
              alt="icon-3"
            />
            <h2>brand.</h2>
            <img
              className="icon-3"
              src={icon4}
              alt="icon-4"
            />
          </div>
          <p
            className="home-content-text"
            dangerouslySetInnerHTML={{ __html: information.content }}
          />
        </div>
      </div>
    </section>
  );
};

export default HomeContent;
