import React, { useEffect, useRef, useState } from 'react';
import './Blog.css';
import BlogList from './BlogList';
import BlogAll from './BlogAll';
import { useNavigate } from 'react-router-dom';

const Blog = () => {
  const [listCategory, setListCategory] = useState([]);
  const [blogList, setBlogList] = useState([]);
  const [blogMain, setBlogMain] = useState({});
  const [blogHeader, setBlogHeader] = useState({});
  const tabBlockRef = useRef([]);
  const tabAdditionalRef = useRef([]);
  const navigate = useNavigate();

  function stripHtml(html) {
    // Temporary element for parsing HTML content
    var tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }

  const handleTabClick = (e) => {
    tabBlockRef.current.forEach((item) => item.classList.remove('active'));
    e.target.classList.add('active');

    const tabNumber = e.target.dataset.tab;
    tabAdditionalRef.current.forEach((item) => {
      item.classList.remove('active');
      if (item.getAttribute('data-tab') === tabNumber) {
        item.classList.add('active');
      }
    });
  };

  useEffect(() => {
    const tabBlock = tabBlockRef.current;
    console.log('Adding event listeners to tab blocks:', tabBlock);
    tabBlock.forEach((item) => {
      if (item) {
        item.addEventListener('click', handleTabClick);
      } else {
        console.warn('Skipping null item in tabBlockRef');
      }
    });

    return () => {
      console.log('Removing event listeners from tab blocks:', tabBlock);
      tabBlock.forEach((item) => {
        if (item) {
          item.removeEventListener('click', handleTabClick);
        } else {
          console.warn('Skipping null item in tabBlockRef during cleanup');
        }
      });
    };
  }, [listCategory]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoryRes, blogRes, headerRes] = await Promise.all([
          fetch('https://api.chuckmotion.com/blog/get-cate-blog'),
          fetch('https://api.chuckmotion.com/blog/get-blog'),
          fetch('https://api.chuckmotion.com/blog/get-blog-header'),
        ]);

        const [categoryData, blogData, headerData] = await Promise.all([
          categoryRes.json(),
          blogRes.json(),
          headerRes.json(),
        ]);

        setListCategory(categoryData);
        setBlogList(blogData);
        setBlogMain(blogData[0]);
        setBlogHeader(headerData);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
    document.title = 'Blog - Chuck Motion';
  }, []);

  return (
    <div className="blog">
      <div className="container">
        <section className="blog-header column col-10">
          <p
            className="blog-header-title column"
            dangerouslySetInnerHTML={{ __html: blogHeader.heading }}
          />
          <div
            className="blog-header-content column"
            dangerouslySetInnerHTML={{ __html: blogHeader.content }}
          />
        </section>
        <section className="blog-inspiration column col-10">
          <div
            onClick={() =>
              navigate(
                `/blog/${stripHtml(blogMain.heading)
                  .replace(/ /g, '-')
                  .toLowerCase()}`,
                {
                  state: { postId: blogMain.post_id },
                }
              )
            }
            className="blog-inspiration-img column">
            <img
              src={blogMain.thumbnail}
              alt=""
            />
          </div>
          <div
            className="blog-inspiration-text column"
            dangerouslySetInnerHTML={{ __html: blogMain.heading }}
          />
        </section>
        <section className="blog-tab colum col-10">
          <div className="blog-tab-list">
            <div
              className="blog-tab-item active"
              data-tab="0"
              ref={(el) => (tabBlockRef.current[0] = el)}>
              <p>All Blog</p>
            </div>
            {listCategory.map((item, index) => (
              <div
                key={index}
                className="blog-tab-item"
                data-tab={item.post_category_id}
                ref={(el) => (tabBlockRef.current[index + 1] = el)}>
                <span dangerouslySetInnerHTML={{ __html: item.heading }} />
              </div>
            ))}
          </div>
        </section>
        <section
          className="blog-list colum col-10 active"
          data-tab="0"
          ref={(el) => (tabAdditionalRef.current[0] = el)}>
          <BlogAll blogList={blogList} />
        </section>
        {listCategory.map((item, index) => (
          <section
            key={index}
            className="blog-list colum col-10"
            data-tab={item.post_category_id}
            ref={(el) => (tabAdditionalRef.current[index + 1] = el)}>
            <BlogList id={item.post_category_id} />
          </section>
        ))}
      </div>
    </div>
  );
};

export default Blog;
