import React, { useEffect, useState } from 'react';
import './Enquires.css';
import EnquiryForm from './EnquiresForm';

const Enquires = () => {
  const [content, setContent] = useState({});
  const [budget, setBudget] = useState({});
  const [service, setService] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [contentRes, budgetRes, serviceRes] = await Promise.all([
          fetch('https://api.chuckmotion.com/enquiries/get-content'),
          fetch('https://api.chuckmotion.com/enquiries/get-budget'),
          fetch('https://api.chuckmotion.com/enquiries/get-service'),
        ]);

        const [contentData, budgetData, serviceData] = await Promise.all([
          contentRes.text(),
          budgetRes.text(),
          serviceRes.text(),
        ]);

        setContent(contentData ? JSON.parse(contentData) : {});
        setBudget(budgetData ? JSON.parse(budgetData) : {});
        setService(serviceData ? JSON.parse(serviceData) : {});
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setLoading(true);
      }
    };

    fetchData();
  }, []);

  return (
    loading && (
      <div className="enquires">
        <div className="container">
          <div className="enquires-thank column col-6">
            <p dangerouslySetInnerHTML={{ __html: content.content }} />
          </div>
          <div className="enquires-form column col-6">
            {
              <EnquiryForm
                budget={budget}
                service={service}
              />
            }
          </div>
        </div>
      </div>
    )
  );
};

export default Enquires;
