import React, { useEffect, useState } from 'react';
import KeyVisual from '../KeyVisual/KeyVisual';
import HomeContent from './HomeContent/HomeContent';
import WorkList from '../WorkList/WorkList';

const Home = () => {
  const [keyVisual, setKeyVisual] = useState({});
  const [workList, setWorkList] = useState([]);
  const [information, setInformation] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjectData = async () => {
      const res = await fetch(
        'https://api.chuckmotion.com/projects/get-projects'
      );
      if (!res.ok) {
        throw new Error('Failed to fetch project data');
      }
      const data = await res.json();
      return data;
    };

    const fetchKeyVisual = async () => {
      const res = await fetch(
        'https://api.chuckmotion.com/work/get-key-visual'
      );
      if (!res.ok) {
        throw new Error('Failed to fetch key visual');
      }
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      return data;
    };

    const fetchInformation = async () => {
      const res = await fetch(
        'https://api.chuckmotion.com/work/work-information'
      );
      if (!res.ok) {
        throw new Error('Failed to fetch work information');
      }
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      return data;
    };

    const fetchData = async () => {
      try {
        const [projectData, keyVisualData, informationData] = await Promise.all(
          [fetchProjectData(), fetchKeyVisual(), fetchInformation()]
        );
        setWorkList(projectData);
        setKeyVisual(keyVisualData);
        setInformation(informationData);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    document.title = 'Chuck Motion';
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <section className="home">
      <KeyVisual keyVisual={keyVisual} />
      <HomeContent information={information} />
      <WorkList workList={workList} />
    </section>
  );
};

export default Home;
