import React, { useCallback, useEffect, useState } from 'react';
import './MoreWork.css';
import { useNavigate } from 'react-router-dom';

const MoreWork = ({ project_id, size }) => {
  const [moreWork, setMoreWork] = useState([]);
  const navigate = useNavigate();

  function stripHtml(html) {
    // Temporary element for parsing HTML content
    var tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }
  const fetchProjectTag = useCallback(async () => {
    try {
      const res = await fetch(
        `https://api.chuckmotion.com/projects/${
          size === 0 ? 'get-more-project-169' : 'get-more-project-916'
        }/${project_id}`
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : [];
      setMoreWork(data);
    } catch (error) {
      console.log(error);
    }
  }, [project_id, size]);

  useEffect(() => {
    fetchProjectTag();
  }, [fetchProjectTag]);

  return (
    <section className="more-work">
      <div className="container">
        <div className="work-list column col-10">
          {moreWork.map((item, index) => (
            <div
              className={`work-item column ${size === 0 ? 'col-6' : 'col-916'}`}
              key={`item-${index}`}>
              <div
                onClick={() =>
                  navigate(
                    `/work/${stripHtml(item.heading)
                      .replace(/ /g, '-')
                      .toLowerCase()}`,
                    { state: { project_id: item.project_id } }
                  )
                }>
                <div className="work-item-image">
                  <img
                    src={item.thumbnail}
                    title="Project Image"
                    alt="Work Thumbnail"
                  />
                </div>
                <h3
                  className="work-item-title"
                  dangerouslySetInnerHTML={{ __html: item.heading }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MoreWork;
