import React from 'react';
import './KeyVisual.css';

const KeyVisual = ({ keyVisual }) => {
  return (
    <section className="key-visual">
      <div className="key-visual-container">
        {keyVisual.thumbnail && (
          <div className="key-visual-thumbnail">
            <video
              autoPlay
              muted
              loop
              playsInline
              controls>
              <source
                src={keyVisual.thumbnail}
                type="video/mp4"
              />
            </video>
          </div>
        )}
      </div>
    </section>
  );
};

export default KeyVisual;
