import React, { useEffect, useState } from 'react';
import './BlogDetail.css';
import { useLocation } from 'react-router-dom';

const BlogDetail = () => {
  const location = useLocation();
  const { id, postId } = location.state || {};
  const [blogDetail, setBlogDetail] = useState({});
  const [category, setCategory] = useState([]);

  function stripHtml(html) {
    // Temporary element for parsing HTML content
    var tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const projectResponse = await fetch(
          `https://api.chuckmotion.com/blog/get-blog-detail/${postId}`
        );
        const projectData = await projectResponse.json();
        const textTitle = stripHtml(projectData.heading);
        document.title = `${textTitle} - Chuck Motion`;
        setBlogDetail(projectData);
      } catch (error) {
        console.error('Failed to fetch project data:', error);
      }
    };
    const fetchProjectTagDetail = async () => {
      const res = await fetch(
        `https://api.chuckmotion.com/blog/get-post-temp/${blogDetail.post_id}`
      );
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setCategory(data);
    };

    fetchProjectTagDetail();
    fetchProjectData();
  }, [id, blogDetail.post_id, postId]);

  const combinedHTML = `${stripHtml(blogDetail.heading)} - ${formatDate(
    blogDetail.time
  )}`;

  function formatDate(isoString) {
    const date = new Date(isoString);

    const options = {
      month: 'long',
      day: 'numeric',
      timeZone: 'Asia/Ho_Chi_Minh',
    };

    return date.toLocaleDateString('en-US', options);
  }

  return (
    <section className="blog-detail">
      <div className="container">
        <div className="blog-detail-wrapper column col-8">
          <div className="blog-detail-header">
            <div className="blog-detail-info column">
              <div className="blog-detail-tags-list">
                {category.map((item, index) => (
                  <React.Fragment key={index}>
                    <p dangerouslySetInnerHTML={{ __html: item.heading }} />
                    {index < category.length - 1 && ', '}
                  </React.Fragment>
                ))}
              </div>
              <h1 className="blog-detail-heading">{combinedHTML}</h1>
            </div>
            <div className="blog-detail-thumbnail column">
              <img
                src={blogDetail.thumbnail}
                title="Blog Image"
                alt="Uploaded"
              />
            </div>
          </div>
          <div
            className="blog-detail-content"
            dangerouslySetInnerHTML={{ __html: blogDetail.content }}
          />
        </div>
      </div>
    </section>
  );
};

export default BlogDetail;
