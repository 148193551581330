import React, { useEffect } from 'react';
import './Loaded.css';
import { preLoaderAnim } from '../../Animation';

const Loaded = () => {
  useEffect(() => {
    preLoaderAnim();
  }, []);

  return (
    <div className="loading">
      <div className="loading-cricle">
        <div className="loading-inner"></div>
        <div className="loading-inner-2"></div>
        <div className="loading-inner-3"></div>
        <div className="loading-inner-4"></div>
        <div className="loading-inner-5"></div>
        <div className="loading-inner-6"></div>
      </div>
    </div>
  );
};

export default Loaded;
