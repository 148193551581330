import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';

const BlogAll = ({ blogList }) => {
  const navigate = useNavigate();

  function stripHtml(html) {
    // Temporary element for parsing HTML content
    var tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }

  function LayoutInPage({ itemInPage }) {
    return itemInPage.slice(0, 12).map((item, index) =>
      item.status !== 1 ? (
        <div
          key={index}
          onClick={() =>
            navigate(
              `/blog/${stripHtml(item.heading)
                .replace(/ /g, '-')
                .toLowerCase()}`,
              {
                state: { postId: item.post_id },
              }
            )
          }
          className="blog-item column">
          <div className="blog-image">
            <img
              src={item.thumbnail}
              title="Blog Image"
              alt="Uploaded"
            />
          </div>
          <h3
            className="blog-title"
            dangerouslySetInnerHTML={{ __html: item.heading }}
          />
        </div>
      ) : null
    );
  }

  const PageItem = ({ pageSize }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const pageCount = Math.ceil(blogList.length / pageSize);

    const handlePageClick = (selectedItem) => {
      setCurrentPage(selectedItem.selected);
      window.scrollTo(0, 0);
    };

    return (
      <>
        <LayoutInPage
          itemInPage={blogList.slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize
          )}
        />
        <ReactPaginate
          previousLabel={
            <span className={`${currentPage === 0 ? 'not' : ''}`}>
              <i class="fa-solid fa-arrow-left-long"></i> Back
            </span>
          }
          nextLabel={
            <span className={`${currentPage === pageCount - 1 ? 'not' : ''}`}>
              Next <i className="fa-solid fa-arrow-right-long"></i>
            </span>
          }
          onPageChange={handlePageClick}
          // pageCount={pageCount}
          marginPagesDisplayed={0}
          pageRangeDisplayed={0}
          containerClassName="blog-pagination column col-12"
          activeClassName="active"
          forcePage={currentPage}
        />
      </>
    );
  };

  return <PageItem pageSize={8} />;
};

export default BlogAll;
