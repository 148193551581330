import React, { useEffect, useState } from 'react';
import './EnquiresForm.css';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  phone: yup.string().required('Telephone is required'),
  position: yup.string().required('Position is required'),
  company: yup.string().required('Company is required'),
  budget: yup.string().required('Budget is required'),
  service: yup.array().required('Service is required'),
});

const EnquiryForm = ({ budget, service }) => {
  const form = useForm({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      position: '',
      company: '',
      budget: '',
      service: '',
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, reset, formState } = form;
  const { errors, isSubmitSuccessful } = formState;

  function stripHtml(html) {
    var tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }

  const [submitted, setSubmitted] = useState(false);

  const handleSubmitProject = async (formData) => {
    const data = { ...formData };
    console.log(data);

    try {
      const url = 'http://localhost:8080/contact/add';
      const opt = {
        method: 'post',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
      };
      const res = await fetch(url, opt);
      const reponseData = await res.json();
      console.log(reponseData);
      setSubmitted(true);
    } catch (error) {
      console.error('Lỗi khi thêm sản phẩm: ', error);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <div className="enquiry-form-container">
      {submitted ? (
        <div className="thank-you-message">
          <h2>Thank you for your enquiry!</h2>
          <p>We will get back to you shortly.</p>
        </div>
      ) : (
        <form
          className="enquiry-form"
          onSubmit={handleSubmit(handleSubmitProject)}
          noValidate>
          <div className="form-group">
            {/* <label htmlFor="name">Name</label> */}
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Full Name"
              required
              {...register('name')}
            />
            {errors.name && <p className="error">{errors.name.message}</p>}
          </div>
          <div className="form-group">
            {/* <label htmlFor="email">Email</label> */}
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              required
              {...register('email')}
            />
            {errors.email && <p className="error">{errors.email.message}</p>}
          </div>
          <div className="form-group">
            {/* <label htmlFor="name">Name</label> */}
            <input
              type="text"
              id="telephone"
              name="phone"
              placeholder="Telephone"
              required
              {...register('phone')}
            />
            {errors.phone && <p className="error">{errors.phone.message}</p>}
          </div>
          <div className="form-group">
            {/* <label htmlFor="name">Name</label> */}
            <input
              type="text"
              id="position"
              name="position"
              placeholder="Position"
              required
              {...register('position')}
            />
            {errors.position && (
              <p className="error">{errors.position.message}</p>
            )}
          </div>
          <div className="form-group">
            {/* <label htmlFor="name">Name</label> */}
            <input
              type="text"
              id="company"
              name="company"
              placeholder="Company"
              required
              {...register('company')}
            />
            {errors.company && (
              <p className="error">{errors.company.message}</p>
            )}
          </div>
          <div className="form-selection">
            <div className="form-group radio-group column col-6">
              <p className="estimate-budget-title">Estimate Budget</p>
              <label className="custom-checkbox">
                <input
                  type="radio"
                  name="budget"
                  value={stripHtml(budget.price_1)}
                  {...register('budget')}
                />
                <span className="checkbox-label">
                  {stripHtml(budget.price_1)}
                </span>
              </label>
              <label className="custom-checkbox">
                <input
                  type="radio"
                  name="budget"
                  value={stripHtml(budget.price_2)}
                  {...register('budget')}
                />
                <span className="checkbox-label">
                  {stripHtml(budget.price_2)}
                </span>
              </label>
              <label className="custom-checkbox">
                <input
                  type="radio"
                  name="budget"
                  value={stripHtml(budget.price_3)}
                  {...register('budget')}
                />
                <span className="checkbox-label">
                  {stripHtml(budget.price_3)}
                </span>
              </label>
              <label className="custom-checkbox">
                <input
                  type="radio"
                  name="budget"
                  value={stripHtml(budget.price_4)}
                  {...register('budget')}
                />
                <span className="checkbox-label">
                  {stripHtml(budget.price_4)}
                </span>
              </label>
              <label className="custom-checkbox">
                <input
                  type="radio"
                  name="budget"
                  value={stripHtml(budget.price_5)}
                  {...register('budget')}
                />
                <span className="checkbox-label">
                  {stripHtml(budget.price_5)}
                </span>
              </label>
              <label className="custom-checkbox">
                <input
                  type="radio"
                  name="budget"
                  value={stripHtml(budget.price_6)}
                  {...register('budget')}
                />
                <span className="checkbox-label">
                  {stripHtml(budget.price_6)}
                </span>
              </label>
            </div>
            <div className="form-group checkbox-group column col-6">
              <p className="product-list-title">What can we help you?</p>
              <label className="custom-checkbox">
                <input
                  type="checkbox"
                  name="service"
                  value={stripHtml(service.service_1)}
                  {...register('service')}
                />
                <span className="checkbox-label">
                  {stripHtml(service.service_1)}
                </span>
              </label>
              <label className="custom-checkbox">
                <input
                  type="checkbox"
                  name="service"
                  value={stripHtml(service.service_2)}
                  {...register('service')}
                />
                <span className="checkbox-label">
                  {stripHtml(service.service_2)}
                </span>
              </label>
              <label className="custom-checkbox">
                <input
                  type="checkbox"
                  name="service"
                  value={stripHtml(service.service_3)}
                  {...register('service')}
                />
                <span className="checkbox-label">
                  {stripHtml(service.service_3)}
                </span>
              </label>
              <label className="custom-checkbox">
                <input
                  type="checkbox"
                  name="service"
                  value={stripHtml(service.service_4)}
                  {...register('service')}
                />
                <span className="checkbox-label">
                  {stripHtml(service.service_4)}
                </span>
              </label>
              <label className="custom-checkbox">
                <input
                  type="checkbox"
                  name="service"
                  value={stripHtml(service.service_5)}
                  {...register('service')}
                />
                <span className="checkbox-label">
                  {stripHtml(service.service_5)}
                </span>
              </label>
              <label className="custom-checkbox">
                <input
                  type="checkbox"
                  name="service"
                  value={stripHtml(service.service_6)}
                  {...register('service')}
                />
                <span className="checkbox-label">
                  {stripHtml(service.service_6)}
                </span>
              </label>
            </div>
          </div>
          <div className="form-button">
            <button className="column col-2">Submit</button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EnquiryForm;
