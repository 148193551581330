import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './WorkList.css';
import ReactPaginate from 'react-paginate';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(useGSAP, ScrollTrigger);

const ITEMS_PER_PAGE_COL6 = 8;
const ITEMS_PER_PAGE_COL916 = 10;

const WorkList = ({ workList }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();

  function stripHtml(html) {
    var tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }

  function LayoutInPage({ col6Items, col916Items }) {
    const combinedItems = [...col6Items];

    col916Items.forEach((item, index) => {
      const position = 0 + index;
      combinedItems.splice(position, 0, item);
    });

    return (
      <div className="work-list column col-10">
        {combinedItems.map((item, index) => (
          <div
            className={`work-item column ${
              item.size === 0 ? 'col-6' : 'col-916'
            } ${item.status === 0 ? 'coming-soon' : ''}`}
            key={`item-${index}`}>
            <div
              onClick={() =>
                navigate(
                  `/work/${stripHtml(item.heading)
                    .replace(/ /g, '-')
                    .toLowerCase()}`,
                  { state: { project_id: item.project_id } }
                )
              }>
              <div className="work-item-image">
                <img
                  src={item.thumbnail}
                  title="Project Image"
                  alt="Work Thumbnail"
                />
                {item.status === 0 ? (
                  <div className="coming-soon-text">
                    <p> Coming Soon </p>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <h3
                className="work-item-title"
                dangerouslySetInnerHTML={{ __html: item.heading }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  }

  const PageItem = () => {
    const col6Items = workList.filter(
      (item) => item.size === 0 && item.status !== 2
    );
    const col916Items = workList.filter(
      (item) => item.size !== 0 && item.status !== 2
    );

    const col6PageCount = Math.ceil(col6Items.length / ITEMS_PER_PAGE_COL6);
    const col916PageCount = Math.ceil(
      col916Items.length / ITEMS_PER_PAGE_COL916
    );
    const pageCount = Math.max(col6PageCount, col916PageCount);

    const handlePageClick = ({ selected }) => {
      setCurrentPage(selected);
      window.scrollTo(0, 0);
    };

    const currentCol6Items = col6Items.slice(
      currentPage * ITEMS_PER_PAGE_COL6,
      (currentPage + 1) * ITEMS_PER_PAGE_COL6
    );
    const currentCol916Items = col916Items.slice(
      currentPage * ITEMS_PER_PAGE_COL916,
      (currentPage + 1) * ITEMS_PER_PAGE_COL916
    );

    // setLoading(true);

    return (
      <>
        <LayoutInPage
          col6Items={currentCol6Items}
          col916Items={currentCol916Items}
        />
        <div className="container">
          <ReactPaginate
            previousLabel={
              <span className={`${currentPage === 0 ? 'not' : ''}`}>
                <i className="fa-solid fa-arrow-left-long"></i> Back
              </span>
            }
            nextLabel={
              <span className={`${currentPage === pageCount - 1 ? 'not' : ''}`}>
                Next <i className="fa-solid fa-arrow-right-long"></i>
              </span>
            }
            onPageChange={handlePageClick}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            containerClassName="work-pagination column col-12"
            activeClassName="active"
            forcePage={currentPage}
          />
        </div>
      </>
    );
  };

  return (
    <section className="work">
      <div className="container">
        <PageItem />
      </div>
    </section>
  );
};

export default WorkList;
