import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';
// import logo from './logo.png';

const Header = ({ menu, headerMeta, infomation, social }) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const isActive = (pathname) => {
    if (pathname === '/work') {
      // Kiểm tra nếu đường dẫn chính xác là '/work' hoặc đường dẫn bắt đầu bằng '/work/'
      return (
        location.pathname === '/work' ||
        location.pathname.startsWith('/work/') ||
        location.pathname === '/'
      );
    }
    // Cho phép kích hoạt đường dẫn chính xác, bao gồm cả đường dẫn con
    return location.pathname.startsWith(pathname);
  };
  let lastScrollTop = 0;
  window.addEventListener(
    'scroll',
    function () {
      let currentScrollTop =
        window.scrollY || document.documentElement.scrollTop;
      if (!isOpen) {
        if (currentScrollTop > lastScrollTop) {
          // Người dùng đang scroll xuống, ẩn header
          document.querySelector('.header').classList.add('hide');
        } else {
          // Người dùng đang scroll lên, hiện header
          document.querySelector('.header').classList.remove('hide');
        }
      } else {
        document.querySelector('.header').classList.remove('hide');
      }

      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // Đối với các trình duyệt không hỗ trợ pageYOffset
    },
    false
  );

  const toggleMenu = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
    return isOpen;
  };
  const nextPage = () => {
    setIsOpen(false);
    return isOpen;
  };

  return (
    <>
      <header className={`header is-fixed ${isOpen ? 'is-show' : ''}`}>
        <div className="container-fixed">
          <div className="header-wrapper column col-12">
            <div className="header-wrapper-logo column col-6">
              {/* <Link
                to="/"
                className="header-wrapper-link">
                <img
                  src={logo}
                  alt="logo"
                />
              </Link> */}
            </div>
            <div className="header-wrapper-box column col-6">
              <div
                className="box"
                onClick={toggleMenu}>
                <div className="cricle">
                  <div className="inner"></div>
                  <div className="inner-2"></div>
                  <div className="inner-3"></div>
                  <div className="inner-4"></div>
                  <div className="inner-5"></div>
                  <div className="inner-6"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className={`menu ${isOpen ? 'is-show' : ''}`}>
        <div className="container menu-wrapper">
          <nav className="menu-list column col-6">
            <li>
              <Link
                to={`/${menu.menu_1.toLowerCase()}`}
                className={`menu-link ${
                  isActive(`/${menu.menu_1.toLowerCase()}`) ? 'active' : ''
                }`}
                onClick={nextPage}>
                {menu.menu_1}
              </Link>
            </li>
            <li>
              <Link
                to={`/${menu.menu_2.toLowerCase()}`}
                className={`menu-link ${
                  isActive(`/${menu.menu_2.toLowerCase()}`) ? 'active' : ''
                }`}
                onClick={nextPage}>
                {menu.menu_2}
              </Link>
            </li>
            <li>
              <Link
                to={`/${menu.menu_3.toLowerCase()}`}
                className={`menu-link ${
                  isActive(`/${menu.menu_3.toLowerCase()}`) ? 'active' : ''
                }`}
                onClick={nextPage}>
                {menu.menu_3}
              </Link>
            </li>
            <li>
              <Link
                to={`/${menu.menu_4.toLowerCase()}`}
                className={`menu-link ${
                  isActive(`/${menu.menu_4.toLowerCase()}`) ? 'active' : ''
                }`}
                onClick={nextPage}>
                {menu.menu_4}
              </Link>
            </li>
            <li>
              <Link
                to={`/${menu.menu_5.toLowerCase()}`}
                className={`menu-link ${
                  isActive(`/${menu.menu_5.toLowerCase()}`) ? 'active' : ''
                }`}
                onClick={nextPage}>
                {menu.menu_5}
              </Link>
            </li>
            <li>
              <Link
                to={`/${menu.menu_6.toLowerCase()}`}
                className={`menu-link ${
                  isActive(`/${menu.menu_6.toLowerCase()}`) ? 'active' : ''
                }`}
                onClick={nextPage}>
                {menu.menu_6}
              </Link>
            </li>
            <div className="back-link">
              <p>Site by: leodang.com</p>
              <p>Copyright 2024 by Chuck Motion.</p>
            </div>
          </nav>
          <div className="menu-info column col-6">
            <div className="menu-mail colum col-8">
              <p
                className="heading-mail"
                dangerouslySetInnerHTML={{ __html: infomation.info_heading_1 }}
              />
              <p
                dangerouslySetInnerHTML={{ __html: infomation.info_content_1 }}
              />
            </div>
            <div className="menu-phone colum col-4">
              <p
                className="heading-phone"
                dangerouslySetInnerHTML={{ __html: infomation.info_heading_2 }}
              />
              <p
                dangerouslySetInnerHTML={{ __html: infomation.info_content_2 }}
              />
            </div>
            <div className="menu-sutdio column col-8">
              <p
                className="heading-studio"
                dangerouslySetInnerHTML={{ __html: infomation.info_heading_3 }}
              />
              <p
                dangerouslySetInnerHTML={{ __html: infomation.info_content_3 }}
              />
            </div>
            <div className="menu-social column col-4">
              <p dangerouslySetInnerHTML={{ __html: social.heading }} />
              <ul>
                <li>
                  <Link
                    to={social.link_1}
                    target="_blank">
                    {social.social_1}
                  </Link>
                </li>
                <li>
                  <Link
                    to={social.link_2}
                    target="_blank">
                    {social.social_2}
                  </Link>
                </li>
                <li>
                  <Link
                    to={social.link_3}
                    target="_blank">
                    {social.social_3}
                  </Link>
                </li>
                <li>
                  <Link
                    to={social.link_4}
                    target="_blank">
                    {social.social_4}
                  </Link>
                </li>
                <li>
                  <Link
                    to={social.link_5}
                    target="_blank">
                    {social.social_5}
                  </Link>
                </li>
                <li>
                  <Link
                    to={social.link_6}
                    target="_blank">
                    {social.social_6}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="menu-logo column col-12">
              <img
                src={headerMeta.logo}
                alt="logo"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
