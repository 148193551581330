import React, { useEffect } from 'react';
import SideBar from './Components/Sidebar/SideBar';
import Nav from './Components/Nav/Nav';
import { Route, Routes } from 'react-router-dom';
import Home from './Components/Home/Home';
import './App.css';
import Projects from './Components/Projects/Projects';
import CatePost from './Components/CatePost/CatePost';
import Blog from './Components/Blog/Blog';
import ProjectTag from './Components/ProjectTag/ProjectTag';
import Expertise from './Components/Expertise/Expertise';
import Work from './Components/Work/Work';
import Process from './Components/Process/Process';
import Studio from './Components/Studio/Studio';
import Header from './Components/Header/Header';
import Enquiries from './Components/Enquiries/Enquiries';

const App = () => {
  useEffect(() => {
    document.title = 'Admin - Chuck Motion';
  }, []);
  return (
    <div>
      <div className="wrapper-admin">
        <div className="container-admin">
          <SideBar />
          <main className="mainAdmin">
            <Nav />
            <Routes>
              <Route
                path="/"
                element={<Home />}
              />
              <Route
                path="/header"
                element={<Header />}
              />
              <Route
                path="/projects"
                element={<Projects />}
              />
              <Route
                path="/project-tag"
                element={<ProjectTag />}
              />
              <Route
                path="/cate-post"
                element={<CatePost />}
              />
              <Route
                path="/expertise-page"
                element={<Expertise />}
              />
              <Route
                path="/process-page"
                element={<Process />}
              />
              <Route
                path="/studio-page"
                element={<Studio />}
              />
              <Route
                path="/work-page"
                element={<Work />}
              />
              <Route
                path="/enquiuies-page"
                element={<Enquiries />}
              />
              <Route
                path="/blog"
                element={<Blog />}
              />
            </Routes>
          </main>
        </div>
      </div>
    </div>
  );
};

export default App;
