import React, { useCallback, useEffect, useRef, useState } from 'react';
import './ExpertiseContainer.css';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Expertise = () => {
  const [expertise, setExpertise] = useState([]);

  const main = useRef([]);

  const setRefs = useCallback((el) => {
    if (el && !main.current.includes(el)) {
      main.current.push(el);
    }
  }, []);

  useEffect(() => {
    const fetchExpertiseData = async () => {
      try {
        const res = await fetch(
          'https://api.chuckmotion.com/expertise/get-expertise'
        );
        const data = await res.json();
        setExpertise(data);
      } catch (error) {
        console.error('Failed to fetch expertise data:', error);
      }
    };

    fetchExpertiseData();
    document.title = `Expertise - Chuck Motion`;
  }, []);

  useEffect(() => {
    if (expertise.length > 0) {
      const boxes = gsap.utils.toArray('.expertise-content-desc p');
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          {
            opacity: 0,
            y: 80,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            scrollTrigger: {
              trigger: box,
              start: 'top 70%',
              toggleActions: 'play none none none',
            },
          }
        );
      });
    }
  }, [expertise]);

  useEffect(() => {
    return () => {
      main.current = [];
    };
  }, []);

  return (
    <section className="expertise">
      <div className="container">
        {expertise.map((item, index) => (
          <div
            className="expertise-content column col-12"
            ref={setRefs}
            key={index}>
            <div className="expertise-content-index column">
              <p>{index + 1}/</p>
            </div>
            <div className="expertise-left column">
              <h3
                className="expertise-content-title column"
                dangerouslySetInnerHTML={{ __html: item.heading }}
              />
              <div className="expertise-item-image column">
                <img
                  src={item.thumbnail}
                  alt="Expertise Thumbnail"
                />
              </div>
            </div>
            <p
              className="expertise-content-desc column"
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Expertise;
