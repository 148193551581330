import React, { useCallback, useEffect, useRef, useState } from 'react';
import './Process.css';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Process = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);
  const [process, setProcess] = useState([]);
  const [qna, setQna] = useState([]);
  const [processHeading, setProcessHeading] = useState({});
  const [banner, setBanner] = useState({});
  const [loading, setLoading] = useState(false);

  const main = useRef([]);

  const setRefs = useCallback((el) => {
    if (el && !main.current.includes(el)) {
      main.current.push(el);
    }
  }, []);

  useEffect(() => {
    const fetchProcessData = async () => {
      const res = await fetch(
        'https://api.chuckmotion.com/process/get-process'
      );
      const text = await res.text();
      return text ? JSON.parse(text) : {};
    };

    const fetchQnaData = async () => {
      const res = await fetch('https://api.chuckmotion.com/process/get-qna');
      const text = await res.text();
      return text ? JSON.parse(text) : {};
    };

    const fetchHeading = async () => {
      const res = await fetch(
        'https://api.chuckmotion.com/process/get-process-heading'
      );
      const text = await res.text();
      return text ? JSON.parse(text) : {};
    };

    const fetchBanner = async () => {
      const res = await fetch('https://api.chuckmotion.com/process/get-banner');
      const text = await res.text();
      return text ? JSON.parse(text) : {};
    };

    const fetchData = async () => {
      try {
        const [processData, qnaData, headingData, banner] = await Promise.all([
          fetchProcessData(),
          fetchQnaData(),
          fetchHeading(),
          fetchBanner(),
        ]);
        setProcess(processData);
        setQna(qnaData);
        setProcessHeading(headingData);
        setBanner(banner);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setLoading(true);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (process.length > 0) {
      const boxes = gsap.utils.toArray('.proces-content-item');
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          {
            opacity: 0,
            y: 80,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            scrollTrigger: {
              trigger: box,
              start: 'top 70%',
              toggleActions: 'play none none none',
            },
          }
        );
      });
    }
  }, [process]);

  useEffect(() => {
    return () => {
      main.current = [];
    };
  }, []);

  const handleClickAccordion = (index) => {
    setActiveIndex((prevActiveIndex) =>
      prevActiveIndex === index ? index : index
    );
  };

  useEffect(() => {
    loading &&
      contentRefs.current.forEach((content, index) => {
        if (content) {
          if (activeIndex === index) {
            // Calculate the height in pixels and vw
            const additionalHeightVW = (0.7 * window.innerWidth) / 100; // Convert vw to pixels
            content.style.height = `${
              content.scrollHeight + additionalHeightVW
            }px`;
            content.style.borderBottom = '1px solid #939393';
          } else {
            content.style.height = '0px';
            content.style.borderBottom = 'none';
          }
        }
      });
  }, [activeIndex, loading]);

  return (
    loading && (
      <div className="process">
        <section className="process-container">
          <img
            src={banner.banner}
            alt=""
            className="process-image"
          />
        </section>
        <section
          className="proces-content"
          ref={setRefs}>
          <div className="container">
            <div className="proces-content-text column col-3">
              <p
                className="column col-8"
                dangerouslySetInnerHTML={{
                  __html: processHeading.name_process,
                }}
              />
            </div>
            <div className="proces-content-list column col-9">
              {process.map((item, index) => (
                <div
                  className="proces-content-item"
                  key={index}>
                  <div className="proces-content-index column">
                    <p>{index + 1 + '/'}</p>
                  </div>
                  <div className="proces-content-title column">
                    <p dangerouslySetInnerHTML={{ __html: item.heading }}></p>
                  </div>
                  <div className="proces-content-desc column">
                    <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="process-qa">
          <div className="container">
            <div className="process-qa-text column col-3">
              <p
                className="column col-8"
                dangerouslySetInnerHTML={{ __html: processHeading.name_qna }}
              />
            </div>
            <div className="process-qa-list column col-8">
              {qna.map((item, index) => (
                <div
                  className="process-qa-block"
                  key={index}>
                  <div className="process-qa-container colum col-6">
                    <div
                      className={`process-qa-header ${
                        activeIndex === index ? 'is-active' : ''
                      }`}
                      onClick={() => handleClickAccordion(index)}>
                      <h2 dangerouslySetInnerHTML={{ __html: item.heading }} />
                      <button className="process-qa-icon">
                        <label class="hamburger">
                          <input
                            type="checkbox"
                            checked={activeIndex === index ? true : false}
                          />
                          <svg
                            viewBox="0 0 32 32"
                            onClick={() => handleClickAccordion(index)}>
                            <path
                              class="line line-top-bottom"
                              d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"></path>
                            <path
                              class="line"
                              d="M7 16 27 16"></path>
                          </svg>
                        </label>
                      </button>
                    </div>
                    <div
                      className={`process-qa-content ${
                        activeIndex === index ? 'is-active' : ''
                      }`}
                      ref={(el) => (contentRefs.current[index] = el)}>
                      <p
                        className="process-qa-content-desc"
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      />
                      <div
                        className={`process-qa-image colum col-6 ${
                          activeIndex === index ? 'is-active' : ''
                        }`}>
                        <img
                          src={item.thumbnail}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    )
  );
};

export default Process;
